import React from "react";
import 'react-rangeslider/lib/index.css';
import 'react-dropzone-uploader/dist/styles.css';
import "./src/styles/plyr.css";
import './src/styles/main.css';
import { RecoilRoot } from "recoil";

const transitionDelay = 350;

export const wrapPageElement = ({ element, props }) => {
  return <RecoilRoot {...props}>{element}</RecoilRoot>;
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    );
  }
  return false;
};