// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-choose-media-js": () => import("./../../../src/pages/choose-media.js" /* webpackChunkName: "component---src-pages-choose-media-js" */),
  "component---src-pages-editor-js": () => import("./../../../src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-failed-js": () => import("./../../../src/pages/order-failed.js" /* webpackChunkName: "component---src-pages-order-failed-js" */),
  "component---src-pages-order-success-js": () => import("./../../../src/pages/order-success.js" /* webpackChunkName: "component---src-pages-order-success-js" */),
  "component---src-pages-template-js": () => import("./../../../src/pages/template.js" /* webpackChunkName: "component---src-pages-template-js" */)
}

